<template>
  <div
    class="row   position-relative"
    :class="RoutePage == 'QuizFinal' ? 'm-0 p-0 w-100 big-div-quiz' : 'mt-0 mt-sm-5 w-85 big-div'"
    :style="getPageDesign()"
  >
    <div class="col-12">
      <div>
        <div
          v-for="(pro, ind) in pageDesign.blocksArray"
          :key="'preview' + ind"
          class="mt-4"
          :data-aos="animationType"
              :data-aos-duration="getAnimationSpeedinMillis"
              :data-aos-delay="AOSAnimationDelay(ind)"
        >
          <div
            id="preview"
            :style="getHeightWidth(pro.style)"
            class="content"
            style="position: relative"
          >
            <div
              v-if="pro.type == 'text'"
              :style="
                getColorCss(pro.style) +
                getPaddingCss(pro.style) +
                getMarginCss(pro.style) +
                getColorWithOpacity(pro.style) +
                getTextRotate(pro.style) +
                getLineHeight(pro.style) +
                getTextAlign(pro.style) +
                getFontStyle(pro.style,isDesktopView)
              "
              v-html="pro.content"
            ></div>

            <div
              v-else
              :style="
                'display:flex;justify-content:' + pro.style.position + ';'
              "
            >
              <button :style="getButtonCss(pro.style,isDesktopView)" @click="RestartQuiz">
                <span
                  :style="{
                    opacity: `${pro.style.textOpacity}%`,
                    fontSize: `${pro.style.fontSize}px`,
                  }"
                >
                  {{ pro.text }}
                </span>
                {{ pro.style.alignItems }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
export default {
  mixins: [cssFunctionMixin],
   props: ['RoutePage','isDesktopView',  "animationType",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis"],  
  components: {
  },
  data() {
    return {
      activeSideBar: "pageStyle",
      pageDesign: {},
    };
  },
  computed: {
    ...mapGetters(["getSelectedPage", "getPreviewSettings"]),
  },
  created() {
    this.pageDesign = this.getStartedPageValues();
  },
  methods: {
    AOSAnimationDelay(index) {
      const baseDelay = parseInt(this.getAnimationSpeedinMillis) || 200;
      return this.getIsAnimationEnabled ? (index + 1) * baseDelay : 50;
    },
    RestartQuiz() {
      window.location.href = this.$router.history.current.path;
    },
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; padding:" +
        this.pageDesign.pageStyles.paddingTop +
        "px " +
        this.pageDesign.pageStyles.paddingRight +
        "px " +
        this.pageDesign.pageStyles.paddingBottom +
        "px " +
        this.pageDesign.pageStyles.paddingLeft +
        "px; " +
        "color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; height:" +
        this.pageDesign.pageStyles.height +
        "px; overflow-y:scroll; scroll-behaviour:smooth;overflow-x:hidden;";

      return design;
    },
    getStartedPageValues() {
      let getStartedDesignValues = {};
      if (this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },
  },
};
</script>

<style></style>
